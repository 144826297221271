
<template>
  <div id="Product-Update">
    <Form />
  </div>
</template>

<script>
import Form from "./Form";

export default {
  // 引入的组件
  components: { Form },
  data: () => {
    return {};
  },
};
</script>

<style lang="less" scoped>
#Product-Update {
}
</style>
