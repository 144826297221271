import "@/components/js/_form_common.js";

export default {
  data: () => {
    return {
      formModel: {
        item: {
          order_id: undefined,
          power: undefined,
          scheme_id: undefined,
          fil: 0,
          usdt: 0,
          pay_pwd: undefined,
          pay_pwd_plainCode: undefined,
        },
        rules: {
          order_id: [{
            required: true,
            validator: (rule, value, callback) => {
              if (!value) callback(new Error("必须选择要升级的产品"));
              else callback();
            },
            trigger: ["change", "blur"],
          }, ],
          power: [{
            required: true,
            validator: (rule, value, callback) => {
              if (!value) callback(new Error("输入不能为空"));
              else callback();
            },
            trigger: ["change", "blur"],
          }, ],
          fil: [{
            required: true,
            trigger: ["change", "blur"],
          }, ],
          usdt: [{
            required: true,
            trigger: ["change", "blur"],
          }, ],
          pay_pwd_plainCode: [{
            required: true,
            validator: (rule, value, callback) => {
              if (!value) callback(new Error("输入不能为空"));
              else callback();
            },
            trigger: ["change", "blur"],
          }, ],
        },
        password_enter_show: false,
      },
      upgrade_purchasr_select: {},
      upgrade_purchasr_list: [],
      upgrade_package_select: {},
      upgrade_package: [{
        id: '1',
        name: '方案1 ：全部封装',
        content: '* 10Fil',
      }, {
        id: '2',
        name: '方案2 ：结余补差',
        content: '除 4\n(获得 整数部分 和 余数部分 )\n整数部分 + [448u * (4 - 余数部分)]',
      }, {
        id: '3',
        name: '方案3 ：结余封装',
        content: '除 4\n(获得 整数部分 和 余数部分 )\n整数部分 + [10Fil * 余数部分]',
      }]
    };
  },
  watch: {
    upgrade_purchasr_select() {
      this.compute_upgrade_package();
    },
    upgrade_package_select() {
      this.compute_upgrade_package();
    }
  },
  // 方法
  methods: {
    async get_upgrade_purchasr_list() {
      await this.$axios.get("power/cluster-power").then((res) => {
        try {
          if (res.code === 0) this.upgrade_purchasr_list = res.data;
        } catch (err) {
          console.log(this.$route.path, "\nlogin catch err: \n", err);
        }
      });
    },
    compute_upgrade_package() {
      const a1 = this.upgrade_purchasr_select;
      const a2 = this.upgrade_package_select;

      this.formModel.item.fil = 0;
      this.formModel.item.usdt = 0;
      this.formModel.item.power = 0;

      if (a1.quantity && a2.id) {
        const a = a1.quantity;
        switch (a2.id) {
          case '1':
            this.formModel.item.power = parseInt(a);
            this.formModel.item.fil = a * 10;
            break;
          case '2':
            if (a < 4) {
              this.formModel.item.power = 1;
              this.formModel.item.usdt = 448 * (4 - a);
            } else {
              this.formModel.item.power = Math.ceil(a / 4)
              if (a % 4)
                this.formModel.item.usdt = 448 * (4 - (a % 4));
            }
            break;
          case '3':
            if (a < 4) {
              this.formModel.item.fil = a * 10;
              this.formModel.item.power = a;
            } else {
              this.formModel.item.fil = 10 * (a % 4);
              this.formModel.item.power = parseInt(Math.floor(a / 4)) + parseInt(a % 4);
            }
            break;
        }
      }
    },
    form_set_rules() {
      const coin = (rule, value, callback) => {
        if (!value && parseInt(value) !== 0)
          callback(new Error("输入不能为空"));
        else if (!this.formModel.item.fil && !this.formModel.item.usdt)
          callback(new Error(`支付币种不能全部为空`));
        else callback();
      };
      this.formModel.rules.fil[0].validator = coin;
      this.formModel.rules.usdt[0].validator = coin;
    },
    form_submit() {
      this.$refs["formModel"].validate((valid) => {
        if (valid) this.formModel.password_enter_show = true;
      });
    },
    form_password_enter() {
      this.$refs["formModel"].validate((valid) => {
        if (valid) this.form_password_confirm();
      });
    },
    form_password_confirm() {
      // 禁用所有按钮
      this.$("button").css({
        "pointer-events": "none",
      });

      const datas = {
        order_id: this.formModel.item.order_id,
        scheme_id: this.formModel.item.scheme_id,
        pay_pwd: this.$axios.Base64.encode(
          this.formModel.item.pay_pwd_plainCode
        ),
      };
      delete datas.pay_pwd_plainCode;
      this.$axios
        .post(
          "power/purchase-upgrade",
          this.$axios.qs.stringify({
            ...datas,
          })
        )
        .then((res) => {
          // 解禁所有按钮
          this.$("button").css({
            "pointer-events": "auto",
          });
          // 清空提示层
          this.$message.destroy();
          if (res.code === 0) {
            this.$message.success(res.message);
            this.$(this.$el).css({
              "pointer-events": "none",
            });
            // 返回上层
            setTimeout(() => {
              history.go(-1);
            }, 1000);
          } else this.$message.warning(res.message);
        });
    },
  },
  created() {
    // （this）data、methods 已初始化，数据（data）、方法(methods) 尚未 编译成模版，只是存在内存中
    // console.log(this.$route.path, "\n" + new Date().valueOf() + " created: \n");
    this.form_set_rules();
    this.get_upgrade_purchasr_list();
  },
};